import homeLinda from '../../images/linda/homelinda.jpeg' 
import linda1 from '../../images/linda/linda1.jpeg'
import linda2 from '../../images/linda/linda2.jpeg'
import linda3 from '../../images/linda/linda3.jpeg'
import linda4 from '../../images/linda/linda4.jpeg'
import linda5 from '../../images/linda/linda5.jpeg'
import linda6 from '../../images/linda/linda6.jpeg'
import linda7 from '../../images/linda/linda7.jpeg'
import linda8 from '../../images/linda/linda8.jpeg'
import linda9 from '../../images/linda/linda9.jpeg'
import linda10 from '../../images/linda/linda10.jpeg'
import linda11 from '../../images/linda/linda11.jpeg'
import linda12 from '../../images/linda/linda12.jpeg'
import linda13 from '../../images/linda/linda13.jpeg'
import linda14 from '../../images/linda/linda14.jpeg'
import linda15 from '../../images/linda/linda15.jpeg'
import linda16 from '../../images/linda/linda16.jpeg'
import linda17 from '../../images/linda/linda17.jpeg'
import linda18 from '../../images/linda/linda18.jpeg'
import linda19 from '../../images/linda/linda19.jpeg'
import linda20 from '../../images/linda/linda20.jpeg'
import linda21 from '../../images/linda/linda21.jpeg'
import linda22 from '../../images/linda/linda22.jpeg'
import linda23 from '../../images/linda/linda23.jpeg'

import videoVistaHermosa from '../../Video/vistahermosa/videovistahermosa.mp4'
import homeVistaHermosa from '../../images/vistahermosa/homevistahermosa.jpeg'
import vistahermosa1 from '../../images/vistahermosa/vistahermosa1.jpeg'
import vistahermosa2 from '../../images/vistahermosa/vistahermosa2.jpeg'
import vistahermosa3 from '../../images/vistahermosa/vistahermosa3.jpeg'
import vistahermosa4 from '../../images/vistahermosa/vistahermosa4.jpeg'
import vistahermosa5 from '../../images/vistahermosa/vistahermosa5.jpeg'
import vistahermosa6 from '../../images/vistahermosa/vistahermosa6.jpeg'
import vistahermosa7 from '../../images/vistahermosa/vistahermosa7.jpeg'
import vistahermosa8 from '../../images/vistahermosa/vistahermosa8.jpeg'
import vistahermosa9 from '../../images/vistahermosa/vistahermosa9.jpeg'
import vistahermosa10 from '../../images/vistahermosa/vistahermosa10.jpeg'
import vistahermosa11 from '../../images/vistahermosa/vistahermosa11.jpeg'
import vistahermosa12 from '../../images/vistahermosa/vistahermosa12.jpeg'
import vistahermosa13 from '../../images/vistahermosa/vistahermosa13.jpeg'
import vistahermosa14 from '../../images/vistahermosa/vistahermosa14.jpeg'
import vistahermosa15 from '../../images/vistahermosa/vistahermosa15.jpeg'
import vistahermosa16 from '../../images/vistahermosa/vistahermosa16.jpeg'
import vistahermosa17 from '../../images/vistahermosa/vistahermosa17.jpeg'
import vistahermosa18 from '../../images/vistahermosa/vistahermosa18.jpeg'
import vistahermosa19 from '../../images/vistahermosa/vistahermosa19.jpeg'
import vistahermosa20 from '../../images/vistahermosa/vistahermosa20.jpeg'
import vistahermosa21 from '../../images/vistahermosa/vistahermosa21.jpeg'
import vistahermosa22 from '../../images/vistahermosa/vistahermosa22.jpeg'
import vistahermosa23 from '../../images/vistahermosa/vistahermosa23.jpeg'
import vistahermosa24 from '../../images/vistahermosa/vistahermosa24.jpeg'
import vistahermosa25 from '../../images/vistahermosa/vistahermosa25.jpeg'
import vistahermosa26 from '../../images/vistahermosa/vistahermosa26.jpeg'
import vistahermosa27 from '../../images/vistahermosa/vistahermosa27.jpeg'
import vistahermosa28 from '../../images/vistahermosa/vistahermosa28.jpeg'
import vistahermosa29 from '../../images/vistahermosa/vistahermosa29.jpeg'
import vistahermosa30 from '../../images/vistahermosa/vistahermosa30.jpeg'
import vistahermosa31 from '../../images/vistahermosa/vistahermosa31.jpeg'
import vistahermosa32 from '../../images/vistahermosa/vistahermosa32.jpeg'
import vistahermosa33 from '../../images/vistahermosa/vistahermosa33.jpeg'
import vistahermosa34 from '../../images/vistahermosa/vistahermosa34.jpeg'
import vistahermosa35 from '../../images/vistahermosa/vistahermosa35.jpeg'
import vistahermosa36 from '../../images/vistahermosa/vistahermosa36.jpeg'
import vistahermosa37 from '../../images/vistahermosa/vistahermosa37.jpeg'
import vistahermosa38 from '../../images/vistahermosa/vistahermosa38.jpeg'
import vistahermosa39 from '../../images/vistahermosa/vistahermosa39.jpeg'
import vistahermosa40 from '../../images/vistahermosa/vistahermosa40.jpeg'
import vistahermosa41 from '../../images/vistahermosa/vistahermosa41.jpeg'
import vistahermosa42 from '../../images/vistahermosa/vistahermosa42.jpeg'
import vistahermosa43 from '../../images/vistahermosa/vistahermosa43.jpeg'

import homePasion from '../../images/pasion/homepasion.jpeg'
import pasion1 from '../../images/pasion/pasion1.jpeg'
import pasion2 from '../../images/pasion/pasion2.jpeg'
import pasion3 from '../../images/pasion/pasion3.jpeg'
import pasion4 from '../../images/pasion/pasion4.jpeg'
import pasion5 from '../../images/pasion/pasion5.jpeg'
import pasion6 from '../../images/pasion/pasion6.jpeg'
import pasion7 from '../../images/pasion/pasion7.jpeg'
import pasion8 from '../../images/pasion/pasion8.jpeg'
import pasion9 from '../../images/pasion/pasion9.jpeg'
import pasion10 from '../../images/pasion/pasion10.jpeg'
import pasion11 from '../../images/pasion/pasion11.jpeg'
import pasion12 from '../../images/pasion/pasion12.jpeg'
import pasion13 from '../../images/pasion/pasion13.jpeg'
import pasion14 from '../../images/pasion/pasion14.jpeg'
import pasion15 from '../../images/pasion/pasion15.jpeg'
import pasion16 from '../../images/pasion/pasion16.jpeg'
import pasion17 from '../../images/pasion/pasion17.jpeg'
import pasion18 from '../../images/pasion/pasion18.jpeg'
import pasion19 from '../../images/pasion/pasion19.jpeg'
import pasion20 from '../../images/pasion/pasion20.jpeg'
import pasion21 from '../../images/pasion/pasion21.jpeg'
import pasion22 from '../../images/pasion/pasion22.jpeg'
import pasion23 from '../../images/pasion/pasion23.jpeg'
import pasion24 from '../../images/pasion/pasion24.jpeg'
import pasion25 from '../../images/pasion/pasion25.jpeg'
import pasion26 from '../../images/pasion/pasion26.jpeg'
import pasion27 from '../../images/pasion/pasion27.jpeg'
import pasion28 from '../../images/pasion/pasion28.jpeg'
import pasion29 from '../../images/pasion/pasion29.jpeg'
import pasion30 from '../../images/pasion/pasion30.jpeg'
import pasion31 from '../../images/pasion/pasion31.jpeg'
import pasion32 from '../../images/pasion/pasion32.jpeg'
import pasion33 from '../../images/pasion/pasion33.jpeg'
import pasion34 from '../../images/pasion/pasion34.jpeg'
import pasion35 from '../../images/pasion/pasion35.jpeg'
import pasion36 from '../../images/pasion/pasion36.jpeg'
import pasion37 from '../../images/pasion/pasion37.jpeg'
import pasion38 from '../../images/pasion/pasion38.jpeg'
import pasion39 from '../../images/pasion/pasion39.jpeg'
import pasion40 from '../../images/pasion/pasion40.jpeg'
import pasion41 from '../../images/pasion/pasion41.jpeg'
import pasion42 from '../../images/pasion/pasion42.jpeg'

import homeParaiso from '../../images/paraiso/homeparaiso.jpeg'
import paraiso1 from '../../images/paraiso/paraiso1.jpeg'
import paraiso2 from '../../images/paraiso/paraiso2.jpeg'
import paraiso3 from '../../images/paraiso/paraiso3.jpeg'
import paraiso4 from '../../images/paraiso/paraiso4.jpeg'
import paraiso5 from '../../images/paraiso/paraiso5.jpeg'
import paraiso6 from '../../images/paraiso/paraiso6.jpeg'
import paraiso7 from '../../images/paraiso/paraiso7.jpeg'
import paraiso8 from '../../images/paraiso/paraiso8.jpeg'
import paraiso9 from '../../images/paraiso/paraiso9.jpeg'
import paraiso10 from '../../images/paraiso/paraiso10.jpeg'
import paraiso11 from '../../images/paraiso/paraiso11.jpeg'
import paraiso12 from '../../images/paraiso/paraiso12.jpeg'
import paraiso13 from '../../images/paraiso/paraiso13.jpeg'
import paraiso14 from '../../images/paraiso/paraiso14.jpeg'
import paraiso15 from '../../images/paraiso/paraiso15.jpeg'
import paraiso16 from '../../images/paraiso/paraiso16.jpeg'
import paraiso17 from '../../images/paraiso/paraiso17.jpeg'
import paraiso18 from '../../images/paraiso/paraiso18.jpeg'
import paraiso19 from '../../images/paraiso/paraiso19.jpeg'
import paraiso20 from '../../images/paraiso/paraiso20.jpeg'
import paraiso21 from '../../images/paraiso/paraiso21.jpeg'
import paraiso22 from '../../images/paraiso/paraiso22.jpeg'

import homeGrecia from '../../images/grecia/homegrecia.jpeg'
import grecia1 from '../../images/grecia/grecia1.jpeg'
import grecia2 from '../../images/grecia/grecia2.jpeg'
import grecia3 from '../../images/grecia/grecia3.jpeg'
import grecia4 from '../../images/grecia/grecia4.jpeg'
import grecia5 from '../../images/grecia/grecia5.jpeg'
import grecia6 from '../../images/grecia/grecia6.jpeg'
import grecia7 from '../../images/grecia/grecia7.jpeg'
import grecia8 from '../../images/grecia/grecia8.jpeg'
import grecia9 from '../../images/grecia/grecia9.jpeg'
import grecia10 from '../../images/grecia/grecia10.jpeg'
import grecia11 from '../../images/grecia/grecia11.jpeg'
import grecia12 from '../../images/grecia/grecia12.jpeg'
import grecia13 from '../../images/grecia/grecia13.jpeg'
import grecia14 from '../../images/grecia/grecia14.jpeg'
import grecia15 from '../../images/grecia/grecia15.jpeg'
import grecia16 from '../../images/grecia/grecia16.jpeg'
import grecia17 from '../../images/grecia/grecia17.jpeg'
import grecia18 from '../../images/grecia/grecia18.jpeg'
import grecia19 from '../../images/grecia/grecia19.jpeg'
import grecia20 from '../../images/grecia/grecia20.jpeg'
import grecia21 from '../../images/grecia/grecia21.jpeg'




const assetsPasion = {
    photos: [pasion1, pasion2, pasion3, pasion4, pasion5, pasion6, pasion7, pasion8, pasion9, pasion10, pasion11,
    pasion12, pasion13, pasion14, pasion15, pasion16, pasion17, pasion18, pasion19, pasion20, pasion21, pasion22,
    pasion23, pasion24, pasion25, pasion26, pasion27, pasion28, pasion29, pasion30, pasion31, pasion32, pasion33,
    pasion34, pasion35, pasion36, pasion37, pasion38, pasion39, pasion40, pasion41, pasion42],
    homePhoto: homePasion,
    description: 'Villa Pasión',
    link: 'https://www.airbnb.es/rooms/36590311?source_impression_id=p3_1659651671_0CBqrDzYZoZ6VcnY',
    region: 'Jarabacoa',
    locationLink: "https://www.google.com/maps?q=19.11957359313965,-70.62120056152344&z=17&hl=es",
    capacity: '12',
    name: 'pasion'
}
const assetsLinda = {
    photos: [linda1, linda2, linda3, linda4, linda5, linda6, linda7, linda8, linda9, linda10, linda11, linda12,
        linda13, linda14, linda15, linda16, linda17, linda18, linda19, linda20, linda21, linda22, linda23],
    homePhoto: homeLinda,
    description: 'Villa Linda',
    link: 'https://www.airbnb.es/rooms/38516892?source_impression_id=p3_1659617388_q7uDqFaSWnua7TgS',
    region: 'Jarabacoa',
    locationLink: "https://www.google.com/maps?q=19.11957359313965,-70.62120056152344&z=17&hl=es",
    capacity: '8',
    name: 'pasion'
}
const assetsParaiso = {
    photos: [paraiso1, paraiso2, paraiso3, paraiso4, paraiso5, paraiso6, paraiso7, paraiso8, paraiso9, paraiso10,
        paraiso11, paraiso12, paraiso13, paraiso14, paraiso15, paraiso16, paraiso17, paraiso18, paraiso19,
        paraiso20, paraiso21, paraiso22],
    homePhoto: homeParaiso,
    description: 'Villa Paraiso',
    link: 'https://www.airbnb.es/rooms/41610388?source_impression_id=p3_1659651644_2z%2FdY%2FnJvkGUUKRx',
    region: 'Manoguayabo - Santo Domingo Oeste',
    locationLink: "https://www.google.com/maps/place/18%C2%B028'31.0%22N+70%C2%B000'33.7%22W/@18.4752846,-70.0115423,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xef647601ed39d607!8m2!3d18.4752846!4d-70.0093536?hl=es",
    capacity: '15',
    name: 'pasion'
}
const assetsVistaHermosa = {
    photos: [vistahermosa1, vistahermosa2, vistahermosa3, vistahermosa4, vistahermosa5, vistahermosa6, vistahermosa7,
        vistahermosa8, vistahermosa9, vistahermosa10, vistahermosa11, vistahermosa12, vistahermosa13, vistahermosa14,
        vistahermosa15, vistahermosa16, vistahermosa17, vistahermosa18, vistahermosa19, vistahermosa20, vistahermosa21,
        vistahermosa22, vistahermosa23, vistahermosa24, vistahermosa25, vistahermosa26, vistahermosa27, vistahermosa28,
        vistahermosa29, vistahermosa30, vistahermosa31, vistahermosa32, vistahermosa33, vistahermosa34, vistahermosa35,
        vistahermosa36, vistahermosa37, vistahermosa38, vistahermosa39, vistahermosa40, vistahermosa41, vistahermosa42,
        vistahermosa43 ],
    homePhoto: homeVistaHermosa,
    video: videoVistaHermosa,
    description: 'Vista Hermosa',
    link: 'https://www.airbnb.es/rooms/53071179?source_impression_id=p3_1659651612_QZNEeVDIb%2FZO5kmP',
    region: 'Jarabacoa',
    locationLink: "https://www.google.com/maps?q=19.117631912231445,-70.62246704101562&z=17&hl=es",
    capacity: '12',
    name: 'pasion'
}

const assetsGrecia = {
    photos: [grecia1, grecia2, grecia3, grecia4, grecia5, grecia6, grecia7, grecia8, grecia9, grecia10, grecia11,
        grecia12, grecia13, grecia14, grecia15, grecia16, grecia17, grecia18, grecia19, grecia20, grecia21],
    homePhoto: homeGrecia, 
    description: 'Villa Grecia',
    region: 'Bonao - entrada de Constanza',
    locationLink: "https://www.google.com/maps?q=19.01980972290039,-70.45092010498047&z=17&hl=es",
    capacity: '14',
    name: 'pasion'
}

export {assetsLinda, assetsParaiso, assetsPasion, assetsVistaHermosa, assetsGrecia}